/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 14/4/2021
 * @Example
 */

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, MoneyInput, Radio, Typography } from 'diginet-core-ui/components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../config';
import * as W39F3015Actions from '../../../../redux/W3X/W39F3015/W39F3015_actions';
import * as W39F3016Actions from '../../../../redux/W3X/W39F3016/W39F3016_actions';
import History from '../../../libs/history';
import { replaceAndParseToNumber } from '../W39F3002/utils/commons';
import { useStyle } from './style';

W39F3015.propTypes = {
    open: PropTypes.bool,
    handleOpenPopup: PropTypes.func,
    dataCboComputeMethod: PropTypes.array,
    goalProps: PropTypes.object,
};

W39F3015.defaultProps = {
    open: false,
    handleOpenPopup: null,
    dataCboComputeMethod: [],
    goalProps: {},
};

function W39F3015(props) {
    const classes = useStyle();

    const { open, handleOpenPopup, dataCboComputeMethod, goalProps } = props;
    const { GoalID, Type } = goalProps;

    let newDataCboComputeMethod = dataCboComputeMethod.filter((i) => i.ComputeMethod !== '');
    if (Type === 'Key') {
        newDataCboComputeMethod = newDataCboComputeMethod.filter((i) => i.ComputeMethod !== 'BaseOnKey');
    }

    const [data, setData] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);

    const typingRef = useRef(null);
    const originData = useRef(null);

    const dispatch = useDispatch();

    const loadData = () => {
        const param = {
            GoalID: GoalID ?? '',
        };
        dispatch(
            W39F3016Actions.getData(param, (err, dt) => {
                if (err) {
                    const msg = err.message || Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
                if (dt) {
                    originData.current = dt;
                    setData(dt);
                }
            })
        );
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (key, value) => {
        if (typingRef.current) {
            clearInterval(typingRef.current);
        }
        typingRef.current = setTimeout(() => {
            setData({ ...data, [key]: value });
        }, 500);
    };

    const handleUpdate = () => {
        const param = {
            ...data,
            Target: replaceAndParseToNumber(data?.Target ?? 0),
            Value: replaceAndParseToNumber(data?.Value ?? 0),
            GoalID: GoalID ?? '',
            Mode: 0,
        };
        dispatch(
            W39F3015Actions.edit(param, async (err, dt) => {
                if (err) {
                    const msg = err.message || Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
                if (dt?.Status === 0) {
                    await saveHistory();
                    setIsUpdated(true);
                    handleOpenPopup('W39F3015', false, true);
                    return Config.notify.show('success', Config.lang('Cap_nhat_cach_tinh_ket_qua_muc_tieu_thanh_cong'), 2000);
                } else if (dt?.Status === 1) {
                    return Config.notify.show('error', Config.lang('Cap_nhat_cach_tinh_ket_qua_muc_tieu_that_bai'), 2000);
                }
            })
        );
    };

    const saveHistory = async () => {
        if (!data) return false;
        if (GoalID) {
            const _data = {
                ComputeMethod: data?.ComputeMethod ?? '',
                Target: data?.Target ?? '',
                Value: data?.Value ?? '',
            };

            const _dataOld = {
                ComputeMethod: originData?.current?.ComputeMethod ?? '',
                Target: originData?.current?.Target ?? '',
                Value: originData?.current?.Value ?? '',
            };

            const captions = {
                ComputeMethod: 'Cach_tinh_ket_qua',
                Target: 'Chi_tieu',
                Value: 'Ket_qua',
            };

            const options = {
                data: _data,
                dataCompare: _dataOld,
                captions: captions,
                action: 1,
                ModuleID: 'D39',
                TransID: GoalID ?? '',
                TransactionID: 'W39F3015',
                TransactionName: 'Cách tính kết quả của mục tiêu',
            };

            const history = new History(options);
            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            return Config.notify.show('error', Config.lang('Luu_lich_su_khong_thanh_cong'), 2000);
        }
    };

    return (
        <Modal width={'25%'} style={{ minWidth: 250 }} open={open} onClose={(e) => handleOpenPopup('W39F3015', false, isUpdated)}>
            <ModalHeader>{Config.lang('Thiet_lap_cach_tinh_ket_qua_muc_tieu')}</ModalHeader>
            <ModalBody>
                {newDataCboComputeMethod.map((i, idx) => {
                    return (
                        <Radio
                            key={i?.ComputeMethod ?? idx}
                            checked={i?.ComputeMethod === data?.ComputeMethod}
                            label={i?.ComputeMethodName ?? ''}
                            name={'computeMethod'}
                            onChange={(e) => setData({ ...data, ComputeMethod: e.value })}
                            value={i?.ComputeMethod ?? ''}
                            style={{ marginBottom: 15, width: '100%' }}
                        />
                    );
                })}
                <div className={`${classes.flexColumn} ${classes.gap10} ${classes.ml30}`}>
                    <div className={`${classes.divInput} ${classes.gap10} ${classes.alignCenter}`}>
                        <Typography type={'h3'} color={data?.ComputeMethod !== 'Target' ? 'secondary' : 'default'}>
                            {Config.lang('Chi_tieu')}
                        </Typography>
                        <MoneyInput
                            disabled={data?.ComputeMethod !== 'Target'}
                            viewType={'outlined'}
                            onChange={(e) => handleChange('Target', e.target.value)}
                            value={data?.Target ?? ''}
                            style={{ margin: 0 }}
                            allowZero
                            disabledNegative
                            thousandSeparator={','}
                        />
                    </div>
                    <div className={`${classes.divInput} ${classes.gap10} ${classes.alignCenter}`}>
                        <Typography type={'h3'} color={data?.ComputeMethod !== 'Target' ? 'secondary' : 'default'}>
                            {Config.lang('Ket_qua')}
                        </Typography>
                        <MoneyInput
                            disabled={data?.ComputeMethod !== 'Target'}
                            viewType={'outlined'}
                            onChange={(e) => handleChange('Value', e.target.value)}
                            value={data?.Value ?? ''}
                            style={{ margin: 0 }}
                            allowZero
                            disabledNegative
                            thousandSeparator={','}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button viewType={'filled'} color={'info'} startIcon={'Refresh'} onClick={() => handleUpdate()} text={Config.lang('Cap_nhat')} />
            </ModalFooter>
        </Modal>
    );
}

export default W39F3015;
