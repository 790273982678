import React, { useEffect, useRef, useState } from "react";
import { Collapse, Typography, MoneyInput, Modal, ModalBody, ModalHeader } from "diginet-core-ui/components";
import Icon, { ArrowUp, MenuIndentSub, ChatBubble } from "diginet-core-ui/icons";
import { useDispatch } from "react-redux";
import * as W39F3011Actions from "../../../../redux/W3X/W39F3011/W39F3011_actions";
import Config from "../../../../config";
import _ from "lodash";
import moment from "moment";
import History from "../../../libs/history";
import W39F3002 from "../W39F3002/W39F3002";
import W39F6000 from "../W39F6000/W39F6000";

const styles = {
    divGroupGoalName: {
        display: "flex",
        flexWrap: "wrap",
        padding: "10px 0",
        alignItems: "center",
        borderBottom: "2px solid",
    },
    iconExpandTransition: {
        display: "inline-flex",
        transition: "all 0.2s ease-in-out",
    },
    divListGoalCont: {
        overflow: "auto",
    },

    textEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },

    divOtherGoalField: {
        padding: "10px 16px",
    },

    gridGoal: {
        display: "grid",
        // gridTemplateColumns: `minmax(500px, 11fr) minmax(100px, 1fr) minmax(145px, 1fr) repeat(3, minmax(120px, 1fr))`,
        width: "fit-content",
        alignItems: "center",
    },

    divGoalTitle: {
        height: "100%",
    },
    gridTR: {
        display: "grid",
        // gridTemplateColumns: `minmax(440px, 11fr) minmax(100px, 1fr) minmax(145px, 1fr) repeat(3, minmax(120px, 1fr))`,
        width: "fit-content",
        alignItems: "center",
    },
    divGoal: {
        borderTop: "1px solid #D4D5D9",
    },
};

const W39F3011GroupGoal = props => {
    const { dataMaster, dataGroupGoal, dataGoal, setDataGoal, StatusID, IsEdit, mode, dataEmployee, onRemoveRow } = props;
    const [dataGoalFilter, setDataGoalFilter] = useState(_.find(dataGoal, o => o.GroupGoalID === dataGroupGoal.GroupGoalID));
    const [expand, setExpand] = useState(true);
    const numEvaluationLevel = dataMaster?.NumEvaluationLevel;

    const listGoalRef = useRef(null);

    useEffect(() => {
        if (dataGoalFilter) {
            let newDataGoal = dataGoal;
            newDataGoal[_.findIndex(dataGoal, o => o.GroupGoalID === dataGoalFilter.GroupGoalID)] = dataGoalFilter;
            setDataGoal(newDataGoal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGoalFilter]);

    let position = {
        left: 0,
        x: 0,
    };

    const toggleExpand = () => {
        setExpand(!expand);
    };

    const onMouseDown = e => {
        position = {
            left: listGoalRef.current.scrollLeft,
            x: e.clientX,
        };

        listGoalRef.current.addEventListener("mousemove", onMouseMove);
        listGoalRef.current.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = e => {
        const dx = e.clientX - position.x;

        // Scroll the element
        if (listGoalRef.current) {
            listGoalRef.current.style.cursor = "grabbing";
            listGoalRef.current.style.userSelect = "none";
            listGoalRef.current.scrollLeft = position.left - dx;
        }
    };

    const onMouseUp = () => {
        listGoalRef.current.style.cursor = null;
        listGoalRef.current.style.removeProperty("user-select");
        listGoalRef.current.removeEventListener("mousemove", onMouseMove);
        listGoalRef.current.removeEventListener("mouseup", onMouseUp);
    };
    const renderReviewColumnTitle = (backgroundColor, labelLevel) => {
        return (
            <div
                className="display_col align-center valign-middle"
                style={{ width: 360 / numEvaluationLevel, ...styles.divGoalTitle, ...styles.divOtherGoalField, backgroundColor: backgroundColor }}
            >
                <Typography type={"p2"} center>
                    <Typography center type={"p2"}>
                        {labelLevel || ""}
                    </Typography>
                    <Typography center type={"p2"}>
                        (%{Config.lang("hoan_thanh").toLowerCase()})
                    </Typography>
                </Typography>
            </div>
        );
    };

    return (
        <>
            {(dataGoalFilter?.Object || []).length > 0 && (
                <div>
                    <div style={{ ...styles.divGroupGoalName, borderColor: dataGroupGoal.Color }}>
                        <span
                            onClick={toggleExpand}
                            style={{ ...styles.iconExpandTransition, color: dataGroupGoal.Color, transform: expand ? "" : "rotate(180deg)", cursor: "pointer" }}
                        >
                            <ArrowUp width={24} height={24} viewBox color={"currentColor"} />
                        </span>
                        <Typography type={"h3"} color={dataGroupGoal.Color}>
                            {`${dataGroupGoal.GroupGoalName || ""} (${(dataGoalFilter?.Object || []).length})`}
                        </Typography>
                    </div>
                    <Collapse open={expand}>
                        <div style={{ ...styles.divListGoalCont, gap: 1 }} onMouseDown={onMouseDown} ref={listGoalRef}>
                            <div
                                style={{
                                    ...styles.gridGoal,
                                    gap: 1,
                                    gridTemplateColumns: `minmax(500px, 11fr) minmax(100px, 1fr) minmax(145px, 1fr) repeat(${numEvaluationLevel}, minmax(${
                                        360 / numEvaluationLevel
                                    }px, 1fr))`,
                                }}
                            >
                                <div className="display_col valign-middle" style={{ ...styles.divGoalTitle }}>
                                    <Typography type={"p2"} fullWidth={false}>
                                        {Config.lang("Ten_muc_tieu")}
                                    </Typography>
                                </div>
                                <div
                                    className="display_col valign-middle"
                                    style={{ width: 100, ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}
                                >
                                    <Typography type={"p2"}>{Config.lang("Trong_so")}</Typography>
                                    <Typography type={"p2"}>
                                        (
                                        {_.reduce(
                                            dataGoalFilter?.Object || [],
                                            function (sum, n) {
                                                return sum + n.Proportion;
                                            },
                                            0
                                        )}
                                        )
                                    </Typography>
                                </div>
                                <div
                                    className="display_col valign-middle"
                                    style={{ width: 145, ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}
                                >
                                    <Typography type={"p2"}>{Config.lang("Han_hoan_thanh")}</Typography>
                                </div>
                                {numEvaluationLevel >= 1 && renderReviewColumnTitle("#99E6FF", dataMaster?.LabelLevel1)}
                                {numEvaluationLevel >= 2 && renderReviewColumnTitle("#95F9B2", dataMaster?.LabelLevel2)}
                                {numEvaluationLevel >= 3 && renderReviewColumnTitle("#FFB3B1", dataMaster?.LabelLevel3)}
                            </div>
                            {(dataGoalFilter?.Object || []).map((goalObject, index) => (
                                <W39F3000Goal
                                    key={goalObject.GoalID}
                                    dataGoalFilterDetail={goalObject}
                                    index={{ indexObject: index }}
                                    dataMaster={dataMaster}
                                    dataGoalFilter={dataGoalFilter}
                                    setDataGoalFilter={setDataGoalFilter}
                                    StatusID={StatusID}
                                    IsEdit={IsEdit}
                                    mode={mode}
                                    dataEmployee={dataEmployee}
                                    onRemoveRow={onRemoveRow}
                                />
                            ))}
                        </div>
                    </Collapse>
                </div>
            )}
        </>
    );
};

const W39F3000Goal = props => {
    const { dataGoalFilterDetail, index, dataMaster, dataGoalFilter, setDataGoalFilter, StatusID, IsEdit, mode, dataEmployee, onRemoveRow } = props;
    const [data, setData] = useState(dataGoalFilterDetail);
    const [expandTargetResult, setExpandTargetResult] = useState(false);
    const [showResult1, setShowResult1] = useState(true);
    const [showResult2, setShowResult2] = useState(true);
    const [showResult3, setShowResult3] = useState(true);
    const [valueFocus, setValueFocus] = useState(false);
    const [targetFocus, setTargetFocus] = useState(false);
    const [isOpenPopW39F3002, setIsOpenPopW39F3002] = useState(false);
    const [isOpenPopW39F6000, setIsOpenPopW39F6000] = useState(false);
    const numEvaluationLevel = dataMaster?.NumEvaluationLevel;

    const dispatch = useDispatch();

    const Language = useRef(Config.language || "84");

    useEffect(() => {
        if (data?.Type === "Object") {
            let newObject = dataGoalFilter?.Object || [];
            newObject[index.indexObject] = data;
            setDataGoalFilter(dataGoalFilter => ({
                ...dataGoalFilter,
                Object: newObject,
            }));
        } else if (data?.Type === "Key") {
            let newObject = dataGoalFilter?.Object || [];
            let newKeyResult = newObject[index.indexObject]?.KeyResult || [];
            newKeyResult[index.indexKey] = data;
            newObject[index.indexObject].KeyResult = newKeyResult;
            setDataGoalFilter(dataGoalFilter => ({
                ...dataGoalFilter,
                Object: newObject,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const toggleExpandTargetResult = () => {
        setExpandTargetResult(!expandTargetResult);
    };

    const saveHistoryDeleteRow = async data => {
        if (!data) return false;
        const TransID = dataMaster?.TransID || "";
        if (TransID) {
            // data Content
            const _data = {
                EmployeeName: dataEmployee?.EmployeeName || "",
                GoalPlanName: dataMaster?.GoalPlanName || "",
                GoalName: data?.GoalName || "",
                Proportion: data?.Proportion || "",
                ValidDateTo: data?.ValidDateTo || null,
                Result1: data?.Result1 || "",
                Result2: data?.Result2 || "",
                Result3: data?.Result3 || "",
                Type: data?.Type || "",
            };

            //name Content
            const captions = {
                EmployeeName: "ten_nhan_vien",
                GoalPlanName: "bang_muc_tieu",
                GoalName: "ten_muc_tieu",
                Proportion: "trong_so",
                ValidDateTo: "han_hoan_thanh",
                Result1: "ket_qua_%1%",
                Result2: "ket_qua_%2%",
                Result3: "ket_qua_%3%",
                Type: "Loai",
            };

            const options = {
                data: _data,
                captions: captions,
                action: 3,
                ModuleID: "D39",
                TransID: TransID,
                TransactionID: "W39F3011",
                TransactionName: "Chi tiết review mục tiêu",
            };
            const history = new History(options); //Init history
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const onView = () => {
        //Call popup W39F3002 (@GoalID, @Type)
        setIsOpenPopW39F3002(true);
    };

    const onComment = () => {
        //Call popup W39F6000 (@GoalID, @Type)
        setIsOpenPopW39F6000(true);
    };

    const onDelete = () => {
        const removeElements = elms => elms.forEach(el => el.remove());
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa_muc_tieu_khoi_ban_review_khong?"), () => {
            const params = {
                DivisionID: Config.getDivisionID() || "",
                FormID: "W39F3011",
                UserID: Config.profile.UserID || "",
                Language: Language.current,
                TransID: dataMaster?.TransID || "",
                Mode: 2,
                GoalID: data?.GoalID || "",
            };
            dispatch(
                W39F3011Actions.deleteRow(params, async error => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                        await saveHistoryDeleteRow(data);
                        removeElements(document.querySelectorAll(`.goal-${data.GoalID}`));
                        onRemoveRow(data.GoalID);
                    }
                })
            );
        });
    };
    const onClickHandler = event => {
        let timer;
        clearTimeout(timer);
        if (event.detail === 1) {
            timer = setTimeout(null, 200);
        } else if (event.detail === 2) {
            if (!event.target?.id) return;
            switch (event.target?.id) {
                case "result1":
                    setShowResult1(false);
                    break;
                case "result2":
                    setShowResult2(false);
                    break;
                case "result3":
                    setShowResult3(false);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (key, e) => {
        switch (key) {
            case "Result1":
                if (e) {
                    setData(data => ({
                        ...data,
                        Result1: e?.value || null,
                    }));
                } else {
                    setData(data => ({
                        ...data,
                        Result1: Number((((data?.Value1 || 0) / (data?.Target1 || 1)) * 100).toFixed(2)) || null,
                    }));
                }

                break;
            case "Result2":
                if (e) {
                    setData(data => ({
                        ...data,
                        Result2: e?.value || null,
                    }));
                } else {
                    setData(data => ({
                        ...data,
                        Result2: Number((((data?.Value2 || 0) / (data?.Target2 || 1)) * 100).toFixed(2)) || null,
                    }));
                }
                break;
            case "Result3":
                if (e) {
                    setData(data => ({
                        ...data,
                        Result3: e?.value || null,
                    }));
                } else {
                    setData(data => ({
                        ...data,
                        Result3: Number((((data?.Value3 || 0) / (data?.Target3 || 1)) * 100).toFixed(2)) || null,
                    }));
                }
                break;
            default:
                break;
        }
    };
    
    const renderReviewColumn = (backgroundColor, showResult, setShowResult, result, value, target, key) => {
        return (
            <div
                className="display_col align-center valign-middle divGoalChild"
                id={"result" + key}
                style={{ width: 360 / numEvaluationLevel, ...styles.divGoalTitle, backgroundColor: backgroundColor }}
                onClick={onClickHandler}
            >
                {showResult && (
                    <Typography center type={"p2"}>
                        {result ? result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
                        {result ? "%" : ""}
                    </Typography>
                )}
                {!showResult && data?.ComputeMethod !== "Target" && (
                    <MoneyInput
                        style={{ margin: 0 }}
                        inputStyle={{ textAlign: "center" }}
                        value={result || ""}
                        onBlur={() => setShowResult(true)}
                        onChange={e => handleChange("Result" + key, e)}
                        readOnly={
                            (data?.ComputeMethod === "BaseOnKey" && !_.isEmpty(data?.KeyResult || [])) ||
                            mode === "view" ||
                            !!!IsEdit ||
                            dataMaster?.UserLevel !== key ||
                            !data?.CanReview
                        }
                        decimalDigit={4}
                        disabledNegative
                        autoFocus
                        thousandSeparator={","}
                        allowZero
                    />
                )}
                {!showResult && data?.ComputeMethod === "Target" && (
                    <div className="display_row" style={{ width: "80%" }}>
                        <MoneyInput
                            style={{ margin: 0 }}
                            inputStyle={{ textAlign: "center" }}
                            value={value || ""}
                            onMouseDown={e => {
                                setValueFocus(true);
                            }}
                            onBlur={() => {
                                setValueFocus(false);
                                if (!targetFocus) {
                                    setShowResult(true);
                                    handleChange("Result" + key, null);
                                }
                            }}
                            decimalDigit={4}
                            disabledNegative
                            onChange={e => Object.assign(data, { ["Value" + key]: e?.value || 0 })}
                            autoFocus
                            readOnly={!data.CanReview || mode === "view" || !!!IsEdit || dataMaster?.UserLevel !== key}
                            thousandSeparator={","}
                            allowZero
                        />
                        <div className="DGN-UI-InputBase underlined  default css-1w3ebz3">/</div>
                        <MoneyInput
                            style={{ margin: 0 }}
                            inputStyle={{ textAlign: "center" }}
                            value={target || ""}
                            onMouseDown={e => {
                                setTargetFocus(true);
                            }}
                            onBlur={() => {
                                setTargetFocus(false);
                                if (!valueFocus) {
                                    setShowResult(true);
                                    handleChange("Result" + key, null);
                                }
                            }}
                            decimalDigit={4}
                            disabledNegative
                            onChange={e => Object.assign(data, { ["Target" + key]: e?.value || 0 })}
                            readOnly={!data.CanReview || mode === "view" || !!!IsEdit || dataMaster?.UserLevel !== key}
                            thousandSeparator={","}
                            allowZero
                        />
                    </div>
                )}
            </div>
        );
    };
    return (
        <div className="W39F3011Goal">
            <div
                className={`divGoal goal-${data?.GoalID}`}
                style={data?.Type === "Object" ? { ...styles.gridGoal, ...styles.divGoal, gap: 1, gridTemplateColumns: `minmax(500px, 11fr) minmax(100px, 1fr) minmax(145px, 1fr) repeat(${numEvaluationLevel}, minmax(${360/numEvaluationLevel}px, 1fr))` } : { ...styles.gridTR, ...styles.divGoal, gap: 1, gridTemplateColumns: `minmax(440px, 11fr) minmax(100px, 1fr) minmax(145px, 1fr) repeat(${numEvaluationLevel}, minmax(${360/numEvaluationLevel}px, 1fr))` }}
            >
                <div className="display_row align-center" style={{ ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}>
                    {data.Type === "Object" && (
                        <span
                            onClick={toggleExpandTargetResult}
                            style={{
                                color: "#7F828E",
                                transform: expandTargetResult ? "" : "rotate(90deg)",
                                ...styles.iconExpandTransition,
                                cursor: "pointer",
                                marginRight: 16,
                            }}
                        >
                            <ArrowUp width={24} height={24} viewBox color={"currentColor"} />
                        </span>
                    )}
                    <div className="display_col">
                        <Typography type={"p2"} style={{ ...styles.textEllipsis, cursor: "pointer" }} onClick={onView}>
                            {data?.GoalName || ""} ({data?.ComputeMethodName || ""})
                        </Typography>
                        <div className="display_row align-center">
                            {data.Type === "Object" && (
                                <div className="display_row align-center mgr5" style={{ cursor: "pointer" }} onClick={onView}>
                                    <MenuIndentSub width={24} height={24} viewBox />
                                    <Typography color={"secondary"} type={"p2"}>
                                        {data?.NumberOfKey || "0/0"}
                                    </Typography>
                                </div>
                            )}
                            <div className="display_row align-center" style={{ cursor: "pointer" }} onClick={onComment}>
                                <ChatBubble width={24} height={24} viewBox />
                                <Typography color={"secondary"} type={"p2"}>
                                    {data?.NumComment || "0"}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {IsEdit === 1 && StatusID === 0 && (
                        <Icon
                            id="iconDelete"
                            name="Delete"
                            type="filled"
                            onClick={onDelete}
                            style={{
                                visibility: "hidden",
                                color: "#7F828E",
                                background: "white",
                                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                                marginLeft: 25,
                            }}
                        />
                    )}
                </div>
                <div className="display_col valign-middle" style={{ width: 100, ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}>
                    <Typography type={"p2"}>{data?.Proportion || ""}</Typography>
                </div>
                <div className="display_col valign-middle" style={{ width: 145, ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}>
                    <Typography type={"p2"} style={{ ...styles.textEllipsis }}>
                        {moment(data?.ValidDateTo || "").format("DD/MM/YYYY")}
                    </Typography>
                </div>
                {numEvaluationLevel >= 1 && renderReviewColumn("#99E6FF", showResult1, setShowResult1, data?.Result1, data?.Value1, data?.Target1, 1)}
                {numEvaluationLevel >= 2 && renderReviewColumn("#95F9B2", showResult2, setShowResult2, data?.Result2, data?.Value2, data?.Target2, 2)}
                {numEvaluationLevel >= 3 && renderReviewColumn("#FFB3B1", showResult3, setShowResult3, data?.Result3, data?.Value3, data?.Target3, 3)}
            </div>
            <Collapse open={expandTargetResult} className={'W39F3011-target-result'} style={{ width: "calc(100% - 60px)", marginLeft: 60 }}>
                {data.Type === "Object" &&
                    data.KeyResult.length > 0 &&
                    data.KeyResult.map((goalKey, idx) => (
                        <W39F3000Goal
                            key={goalKey.GoalID}
                            dataGoalFilterDetail={goalKey}
                            index={{ indexObject: index.indexObject, indexKey: idx }}
                            dataMaster={dataMaster}
                            dataGoalFilter={dataGoalFilter}
                            setDataGoalFilter={setDataGoalFilter}
                            StatusID={StatusID}
                            IsEdit={IsEdit}
                            mode={mode}
                            dataEmployee={dataEmployee}
                            onRemoveRow={onRemoveRow}
                        />
                    ))}
            </Collapse>
            {isOpenPopW39F3002 && (
                <Modal
                    darkTheme={true}
                    onClose={e => setIsOpenPopW39F3002(false)}
                    open={isOpenPopW39F3002}
                    pressESCToClose={false}
                    moveable={false}
                    dragAnyWhere={false}
                    moveOutScreen={false}
                    zIndex={1300}
                >
                    <ModalHeader>{Config.lang("Thong_tin_ket_qua_then_chot")}</ModalHeader>
                    <ModalBody style={{ padding: 24 }}>
                        <W39F3002 goalProps={{ GoalID: data?.GoalID || "", Type: data?.Type || "" }} mode={0} />
                    </ModalBody>
                </Modal>
            )}
            {isOpenPopW39F6000 && (
                <Modal
                    darkTheme={true}
                    onClose={e => setIsOpenPopW39F6000(false)}
                    open={isOpenPopW39F6000}
                    pressESCToClose={false}
                    moveable={false}
                    dragAnyWhere={false}
                    top={50}
                    moveOutScreen={false}
                    width={416}
                    zIndex={1300}
                >
                    <ModalHeader></ModalHeader>
                    <ModalBody style={{ padding: 0 }}>
                        <W39F6000 goalProps={{ GoalID: data?.GoalID || "", Type: data?.Type || "" }} />
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};

export default W39F3011GroupGoal;
