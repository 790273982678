/**
 * @Copyright 2021 @DigiNet
 * @Author QUOCUY
 * @Create 4/20/2021
 * @Example
 */

import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, TextInput, Typography, Avatar, Divider, CircularProgress } from "diginet-core-ui/components";
import { Message } from "diginet-core-ui/icons";
import { Save } from "diginet-core-ui/icons";
import { useDispatch } from "react-redux";
import * as W39F3011Actions from "../../../../redux/W3X/W39F3011/W39F3011_actions";
import Config from "../../../../config";
import W39F3011GroupGoal from "./W39F3011GroupGoal";
import History from "../../../libs/history";
import _ from "lodash";
import moment from "moment";
import html2canvas from 'html2canvas';

const styles = {
    modalHeader: {
        padding: "16px 24px 0 24px",
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
    },
    modalBody: {
        padding: 24,
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        inset: 0,
        zIndex: 9999,
        width: "100vw",
        height: "100vh",
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    box: {
        background: "#FFFFFF",
        padding: "7px 16px",
        border: "1px solid",
        boxSizing: "border-box",
        borderRadius: 4,
    },
};

const W39F3011 = props => {
    const { open, onClose, TransID, StatusID, VoucherID, IsEdit, loadDataW39F3010, mode } = props;
    const [formID] = useState("W39F3011");
    const [loading, setLoading] = useState(false);

    const [dataMaster, setDataMaster] = useState({});
    const [dataGroupGoal, setDataGroupGoal] = useState([]);
    const [dataGoal, setDataGoal] = useState([]);
    const [dataEmployee, setDataEmployee] = useState({});
    const [didSave, setDidSave] = useState(false);
    const dispatch = useDispatch();

    const Language = useRef(Config.language || "84");
    const dataOld = useRef({});
    const dataOldDetail = useRef([]);
    const didSaveHistory = useRef(false);

    const userInfoRef = useRef(null);
    const modalBodyRef = useRef(null);

    const loadData = () => {
        const params = {
            DivisionID: Config.getDivisionID() || "",
            FormID: formID,
            UserID: Config.profile.UserID || "",
            Language: Language.current,
            TransID: TransID,
            VoucherID: _.isNil(VoucherID) ? "" : VoucherID,
            Mode: 0,
            GoalID: "",
        };
        setLoading(true);
        dispatch(
            W39F3011Actions.loadPopup(params, (errors, data) => {
                if (errors) {
                    const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    dataOld.current = _.cloneDeep(data?.Master || {});
                    dataOldDetail.current = _.cloneDeep(flattenData(data?.Goal || []));
                    setDataMaster(_.cloneDeep(data?.Master || {}));
                    setDataGroupGoal(_.cloneDeep(data?.GroupGoal || []));
                    setDataGoal(_.cloneDeep(data?.Goal || []));
                }
                setLoading(false);
            })
        );
    };

    const loadDataAfterSave = () => {
        const params = {
            DivisionID: Config.getDivisionID() || "",
            FormID: formID,
            UserID: Config.profile.UserID || "",
            Language: Language.current,
            TransID: TransID,
            Mode: 0,
            GoalID: "",
        };
        setLoading(true);
        dispatch(
            W39F3011Actions.loadPopup(params, (errors, data) => {
                if (errors) {
                    const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    setDataMaster(_.cloneDeep(data?.Master || {}));
                    setDataGroupGoal(_.cloneDeep(data?.GroupGoal || []));
                    setDataGoal(_.cloneDeep(data?.Goal || []));
                    didSaveHistory.current = true;
                }
                setLoading(false);
            })
        );
    };

    useEffect(() => {
        if (open) loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [TransID, open]);

    useEffect(() => {
        setDataEmployee(Config.getUser({ EmployeeID: dataMaster?.EmployeeID || "" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster?.EmployeeID]);

    useEffect(() => {
        if (didSaveHistory.current) {
            saveHistory();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadDataAfterSave]);

    const flattenData = dataArray => {
        let result = [
            ..._.flatMap(_.cloneDeep(dataArray), x => x.Object),
            ..._.flatMap(
                _.flatMap(_.cloneDeep(dataArray), x => x.Object),
                x => x.KeyResult
            ),
        ];
        result.forEach(item => {
            item.ValidDateTo = moment(item.ValidDateTo).format("DD/MM/YYYY");
        });
        return result;
    };

    const onRemoveRow = goalID => {
        dataOldDetail.current = dataOldDetail.current.filter(x => x.GoalID !== goalID);
    };

    const saveHistory = async () => {
        didSaveHistory.current = false;
        if (TransID) {
            // data Content
            const _data = {
                EmployeeName: dataEmployee?.EmployeeName || "",
                GoalPlanName: dataMaster?.GoalPlanName || "",
                NoteLevel1: dataMaster?.NoteLevel1 || "",
                NoteLevel2: dataMaster?.NoteLevel2 || "",
                NoteLevel3: dataMaster?.NoteLevel3 || "",
            };

            //name Content
            const captions = {
                EmployeeName: "ten_nhan_vien",
                GoalPlanName: "bang_muc_tieu",
                NoteLevel1: "ghi_chu_cap_%1%",
                NoteLevel2: "ghi_chu_cap_%2%",
                NoteLevel3: "ghi_chu_cap_%3%",
            };

            let detailCaptions = {
                GoalName: "ten_muc_tieu",
                Proportion: "trong_so",
                ValidDateTo: "han_hoan_thanh",
                Result1: "ket_qua_%1%",
                Result2: "ket_qua_%2%",
                Result3: "ket_qua_%3%",
                Type: "Loai",
            };

            let _dataOld = dataOld.current;
            _dataOld.EmployeeName = dataEmployee?.EmployeeName || "";

            const options = {
                data: _data,
                captions: captions,
                dataCompare: _dataOld,
                action: 1,
                ModuleID: "D39",
                TransID: TransID,
                TransactionID: "W39F3011",
                TransactionName: "Chi tiết review mục tiêu",
            };
            const history = new History(options); //Init history
            history.createDetailHistory("muc_tieu_va_ket_qua_then_chot", flattenData(dataGoal), dataOldDetail.current, detailCaptions, "GoalID", null, options); //Create detail..
            dataOld.current = _.cloneDeep(dataMaster);
            dataOldDetail.current = _.cloneDeep(flattenData(dataGoal));
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const onSave = () => {
        const params = {
            DivisionID: Config.getDivisionID() || "",
            FormID: "W39F3010",
            UserID: Config.profile.UserID || "",
            HostID: "",
            Language: Language.current,
            TransID: TransID,
            UserLevel: dataMaster?.UserLevel || 0,
            NoteLevel1: dataMaster?.NoteLevel1 || "",
            NoteLevel2: dataMaster?.NoteLevel2 || "",
            NoteLevel3: dataMaster?.NoteLevel3 || "",
            Goal: _.map(dataGoal, item => ({...item, Object: _.filter(item?.Object,["CanReview", 1])})),
            Master: dataMaster,
        };
        dispatch(
            W39F3011Actions.save(params, async (errors, data) => {
                setLoading(true);
                if (errors) {
                    const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    setLoading(false);
                    return false;
                } else if (data) {
                    if (data.Status === 1) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                        setDataGoal([]);
                        loadDataAfterSave();
                        setDidSave(true);
                    }
                }
            })
        );
    };

    const handleChange = (key, e) => {
        if (!e) return false;
        switch (key) {
            case "NoteLevel1":
                setDataMaster({ ...dataMaster, NoteLevel1: e?.target?.value || "" });
                break;
            case "NoteLevel2":
                setDataMaster({ ...dataMaster, NoteLevel2: e?.target?.value || "" });
                break;
            case "NoteLevel3":
                setDataMaster({ ...dataMaster, NoteLevel3: e?.target?.value || "" });
                break;
            default:
                break;
        }
    };

    const resetPopup = () => {
        loadDataW39F3010();
        setDataMaster({});
        setDataGroupGoal([]);
        setDataGoal([]);
    };

    const _onClose = () => {
        if (didSave) {
            onClose();
            resetPopup();
            setDidSave(false);
        } else {
            onClose();
            resetPopup();
        }
    };

    const renderNote = (labelLevel, reviewerName, noteLevel, key) => {
        return (
            <div className="display_row align-center mgt25" style={key === 1 ? { marginTop: 24 } : {}}>
                <Typography type="h3" color="primary">
                    {labelLevel || ""}
                </Typography>
                {reviewerName && (
                    <Typography type="p2" color="secondary" style={{ marginLeft: 4 }}>
                        {`(${reviewerName || ""})`}
                    </Typography>
                )}
                <TextInput
                    readOnly={mode === "view" || dataMaster?.UserLevel !== key}
                    style={{ flex: 1, margin: "0 0 0 25px" }}
                    defaultValue={noteLevel || ""}
                    onChange={e => handleChange("NoteLevel" + key, e)}
                />
            </div>
        );
    };

    const exportPDF = async ()  => {
        setLoading(true);
        const [userInfoImg, modalBodyImg] = await Promise.all([htmlToCanvas(userInfoRef.current), htmlToCanvas(modalBodyRef.current)]);
        setLoading(false);
        if (!(userInfoImg && modalBodyImg)) {
            Config.popup.show('ERROR', Config.lang('Co_loi_xay_ra_trong_qua_trinh_xu_ly'));
        } else {
            const myWindow = window.open('', Config.lang('Bang_muc_tieu'));
            myWindow.document.write(
                '<body onload="window.print();window.close();"><img src="' +
                    userInfoImg +
                    '" style="max-width: 30%;" /><img src="' +
                    modalBodyImg +
                    '" style="max-width: 100%;" /></body>'
            );
            myWindow.document.close();
            myWindow.focus();
        }
    };

    const htmlToCanvas = async (element) => {
        let imgUrl = null;
        if (element) {
            const cloneElement = element.cloneNode(true);
            
            const goalElements = cloneElement.querySelectorAll('.W39F3011Goal');
            if (goalElements.length > 0) {
                goalElements.forEach(element => {
                    const arrowIcon = element.querySelector('div.divGoal > div.display_row > span');
                    const collapseTR = element.querySelector('div.W39F3011-target-result');
                    if (arrowIcon) {
                        Object.assign(arrowIcon.style, {
                            transform: 'unset',
                        });
                    }
                    if (collapseTR) {
                        Object.assign(collapseTR.style, {
                            height: 'auto',
                        });
                    }
                });
            }

            Object.assign(cloneElement.style, {
                transform: 'translate(-200vw, -200vh)',
                width: 'fit-content',
                height: 'fit-content',
            });

            document.body.appendChild(cloneElement)

            await html2canvas(cloneElement, {
                scale: 2,
                allowTaint: true,
                useCORS: true,
                scrollX: 0,
                scrollY: -window.scrollY,
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                if (canvas && imgData) {
                    imgUrl = imgData;
                }
                document.body.removeChild(cloneElement);
            });
        }
        return imgUrl;
    };

    return (
        <>
            <Modal
                darkTheme={true}
                onClose={_onClose}
                open={open}
                pressESCToClose={false}
                moveable={false}
                dragAnyWhere={false}
                moveOutScreen={false}
                width={1344}
                top={50}
                zIndex={1300}
            >
                <ModalHeader style={styles.modalHeader}>
                    <div ref={userInfoRef} className="display_row align-center">
                        <Avatar style={{ marginRight: 16 }} readOnly src={dataEmployee?.UserPictureURL || ""} />
                        <div className="display_col">
                            <Typography type="h1">{dataEmployee?.EmployeeName || ""}</Typography>
                            <Typography type="p1" color="secondary">
                                {dataEmployee?.DutyName || ""}
                            </Typography>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody ref={modalBodyRef} style={styles.modalBody}>
                    {loading && (
                        <div style={styles.loadingContainer}>
                            <CircularProgress size={"sm"} />
                        </div>
                    )}
                    <div className="display_row align-between">
                        <Typography type="h1">• {dataMaster?.GoalPlanName || ""}</Typography>
                        <div className="display_row align-between">
                            <div
                                style={{ ...styles.box, borderColor: Config.color ? Config.color["MainColor"] : "#111D5E" }}
                                className="display_col align-center mgr15"
                            >
                                <Typography type="p3" color="primary">
                                    {Config.lang("Giai_doan")}
                                </Typography>
                                <Typography type="h3" color="primary">
                                    {dataMaster?.PeriodName || ""}
                                </Typography>
                            </div>
                            <div
                                style={{ ...styles.box, borderColor: Config.color ? Config.color["MainColor"] : "#111D5E" }}
                                className="display_col align-center"
                            >
                                <Typography type="p3" color="primary">
                                    {Config.lang("Ket_qua_review")}
                                </Typography>
                                <Typography type="h3" color="primary">
                                    {dataMaster?.Result || ""}%
                                </Typography>
                            </div>
                        </div>
                    </div>

                    {!_.isEmpty(dataGoal) &&
                        dataGroupGoal.map(item => {
                            return (
                                <W39F3011GroupGoal
                                    dataMaster={dataMaster}
                                    dataGroupGoal={item}
                                    dataGoal={dataGoal}
                                    setDataGoal={data => setDataGoal([...data])}
                                    StatusID={StatusID}
                                    IsEdit={IsEdit}
                                    mode={mode}
                                    dataEmployee={dataEmployee}
                                    onRemoveRow={onRemoveRow}
                                />
                            );
                        })}

                    <div className="display_row align-center" style={{ marginTop: 8 }}>
                        <Message color={Config.color ? Config.color["MainColor"] : "#111D5E"} width={32} height={32} />
                        <Typography type="h3" color="primary" className="mgl5">
                            {Config.lang("Nhan_xet_chung")}
                        </Typography>
                    </div>
                    <Divider color={Config.color ? Config.color["MainColor"] : "#111D5E"} height={2} />
                    {dataMaster?.NumEvaluationLevel >= 1 && renderNote(dataMaster?.LabelLevel1, dataMaster?.ReviewerName1, dataMaster?.NoteLevel1, 1)}
                    {dataMaster?.NumEvaluationLevel >= 2 && renderNote(dataMaster?.LabelLevel2, dataMaster?.ReviewerName2, dataMaster?.NoteLevel2, 2)}
                    {dataMaster?.NumEvaluationLevel >= 3 && renderNote(dataMaster?.LabelLevel3, dataMaster?.ReviewerName3, dataMaster?.NoteLevel3, 3)}
                </ModalBody>
                <ModalFooter style={{ padding: "24px 16px" }}>
                    <Button
                        color={'primary'}
                        viewType={'outlined'}
                        text={Config.lang('Xuat_PDF')}
                        startIcon={'Pdf'}
                        style={{ marginRight: 10 }}
                        disabled={!(_.isEmpty(_.differenceWith(flattenData(dataGoal), dataOldDetail.current, _.isEqual)) && _.isEqual(dataMaster, dataOld.current))}
                        onClick={exportPDF}
                    />
                    <Button
                        disabled={
                            mode === "view" ||
                            !!!IsEdit ||
                            (_.isEmpty(_.differenceWith(flattenData(dataGoal), dataOldDetail.current, _.isEqual)) && _.isEqual(dataMaster, dataOld.current))
                        }
                        height={40}
                        viewType={"filled"}
                        color={"info"}
                        startIcon={<Save color={"#FFFFFF"} />}
                        text={Config.lang("Luu")}
                        onClick={onSave}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
};

export default W39F3011;
