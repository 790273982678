/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 5/4/2021
 * @Example
 */

import { LoadPanel } from 'devextreme-react';
import { Avatar, Popover, TextInput, Typography } from 'diginet-core-ui/components';
import Icon from 'diginet-core-ui/icons';
import Picker from 'emoji-picker-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../config';
import * as W39F6000Actions from '../../../../redux/W3X/W39F6000/W39F6000_actions';
import { useStyle } from './style';
import W39F6000GroupCmt from './utils/W39F6000GroupCmt';

const W39F6000 = forwardRef((props, ref) => {
    const classes = useStyle();

    const { goalProps } = props;
    const { GoalID } = goalProps;

    const [current] = useState(() => {
        return Config.getUser({ EmployeeID: Config.profile.HREmployeeID || '' });
    });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpenEmojiPop, setIsOpenEmojiPop] = useState(false);
    const [comment, setComment] = useState('');

    const emojiRef = useRef(null);
    const isUpdated = useRef(false);

    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        isUpdated: () => {
            return isUpdated.current;
        }
    }));

    const loadData = (goalID) => {
        const params = {
            GoalID: goalID ?? '',
            FormID: 'W39F3002'
        };

        dispatch(W39F6000Actions.getComments(params, (err, dt) => {
            if (err) {
                const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                return Config.popup2.show('ERROR', msg);
            }
            if (dt) {
                setData(dt);
            }
        }));
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([loadData(GoalID)]).then(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pasteEmojiToInput = (emoji) => {
        setComment(comment => comment + emoji?.emoji ?? '');
    };

    const addComment = () => {
        if (comment.length > 0) {
            const params = {
                CodeID: GoalID ?? '',
                ModuleID: 'W39',
                FormID: 'W39F3002',
                Message: comment ?? ''
            };

            dispatch(W39F6000Actions.addComment(params, (err, dt) => {
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
                if (dt && Object.keys(dt).length > 0) {
                    setLoading(true);
                    Promise.all([loadData(GoalID)]).then(() => {
                        setLoading(false);
                    });
                    changeIsUpdated();
                    setComment('');
                    return Config.notify.show('success', Config.lang('Them_binh_luan_thanh_cong'), 2000);
                }
            }));
        }
    };

    const groupCmtByDate = () => {
        const newData = [...data];
        const dt = newData.sort((cmt1, cmt2) => {
            return new Date(cmt1?.ECreateDate) - new Date(cmt2?.ECreateDate);
        }).reduce((grCmnt, cmt) => {
            const date = moment(new Date(cmt?.ECreateDate)).format('DD/MM/YYYY');
            (grCmnt[date] = grCmnt[date] || []).push(cmt);
            return grCmnt;
        }, {});
        return dt;
    };

    const groupedData = groupCmtByDate();

    useEffect(() => {
        const cmtCont = document.getElementById('W39F6000-commentCont');
        if (cmtCont.scrollHeight > cmtCont.clientHeight) {
            cmtCont.scrollTop = cmtCont.scrollHeight;
        }
    }, [data]);

    const handleLoading = (flag) => {
        setLoading(flag);
    };

    const handlePressEnter = e => {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            if (e.preventDefault) e.preventDefault();
            addComment();
        }
    };

    const changeIsUpdated = () => {
        isUpdated.current = true;
    };

    return (
        <div id={'W39F6000Comments'} className={`${classes.container}`}>
            <LoadPanel
                shadingColor='rgba(0,0,0,0.4)'
                position={{ my: 'center', of: '#W39F6000Comments' }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <div className={`${classes.flex} ${classes.gap10} ${classes.alignCenter} ${classes.divCurrent}`}>
                <Avatar
                    clearAble={false}
                    width={40}
                    height={40}
                    readOnly
                    outlined={'1px solid white'}
                    style={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}
                    src={current?.UserPictureURL ?? ''}
                />
                <div style={{ overflow: 'hidden' }}>
                    <Typography type={'h3'} className={classes.textNoWrap}>{current?.EmployeeName ?? ''}</Typography>
                    <Typography type={'p2'} color={'secondary'} className={classes.textNoWrap}>{current?.DutyName ?? ''}</Typography>
                </div>
            </div>
            <div id={'W39F6000-commentCont'} className={`${classes.commentCont}`}>
                {Object.keys(groupedData).length > 0 &&
                    Object.keys(groupedData).map((i, idx) =>
                        <W39F6000GroupCmt key={idx} groupData={groupedData[i]} date={i} classes={classes} loadData={loadData} handleLoading={handleLoading} />
                    )
                }
            </div>
            <div className={`${classes.flex} ${classes.columnDirection} ${classes.gap10} ${classes.inputComment}`}>
                <div className={`${classes.flex} ${classes.gap5} ${classes.flexWrap}`}>
                    {/* <Icon id={'Attachment'} name={'Attachment'} viewBox /> */}
                    <Icon ref={emojiRef} id={'Emoji'} name={'Emoji'} viewBox onClick={() => setIsOpenEmojiPop(isOpenEmojiPop => !isOpenEmojiPop)} />
                </div>
                <TextInput
                    endIcon={<Icon id={'Send'} name={'Send'} viewBox style={{ transform: 'rotate(45deg)' }} onClick={() => addComment()} />}
                    viewType={'outlined'}
                    onKeyDown={(e) => handlePressEnter(e)}
                    onChange={e => setComment(e?.target?.value ?? '')}
                    value={comment ?? ''}
                    style={{ margin: 0 }}
                />
            </div>
            <Popover
                open={isOpenEmojiPop}
                removeAfterClose={false}
                onClose={() => setIsOpenEmojiPop(isOpenEmojiPop => !isOpenEmojiPop)}
                anchor={emojiRef}
                width={'fit-content'}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Picker
                    onEmojiClick={(e, emoji) => pasteEmojiToInput(emoji)}
                    disableAutoFocus={true}
                    native
                />
            </Popover>
        </div>
    );
});

W39F6000.propTypes = {
    goalProps: PropTypes.object
};

W39F6000.defaultProps = {
    goalProps: {}
};

export default W39F6000;