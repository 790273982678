/**
 * @Copyright 2021 @DigiNet
 * @Author QUOCUY
 * @Create 4/20/2021
 * @Example
 */

import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonIcon, Typography, Divider, LinearProgress, Popover as CorePopover, Status } from "diginet-core-ui/components";
import Icon from "diginet-core-ui/icons";
import { useDispatch } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W39F3010Actions from "../../../../redux/W3X/W39F3010/W39F3010_actions";
import { Col, Row } from "react-bootstrap";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridActionBar from "../../../grid-container/grid-actionbar";
import InlineSVG from "react-inlinesvg";
import Config from "../../../../config";
import moment from "moment";
import _ from "lodash";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import Popover from "../../../../components/grid-container/popover-action";
import DateBoxPicker from "../../../common/form-material/date-box";
import ButtonGeneral from "../../../common/button/button-general";
import W39F3011 from "../W39F3011/W39F3011";
import History from "../../../libs/history";

const styles = {
    dateFilter: {
        position: "relative",
        color: "#505C80",
        margin: "8px 0 4px",
    },
    labelDateFilter: {
        position: "absolute",
        top: -4,
        background: "white",
        padding: "0 2px",
        left: 14,
        fontWeight: 500,
        fontSize: "calc(14px * 0.75)",
        margin: 0,
        lineHeight: 0.85,
    },
    inputDateFilter: {
        border: "1px solid #505C80",
        borderRadius: 4,
        padding: "10px 14px",
    },
    iconDateFilter: {
        position: "absolute",
        top: 5,
        right: 3,
    },
    listDateRanges: {
        padding: 15,
        borderTop: "1px solid #7F828E",
        borderBottom: "1px solid #7F828E",
        marginTop: 9,
    },
    divButtonDate: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 24,
    },
    divSearchButton: {
        display: "flex",
        justifyContent: "flex-end",
    },
    divColorStatusCont: {
        display: "flex",
        flexDirection: "row",
        gap: 5,
    },
    divColorStatus: {
        width: 16,
        height: 16,
    },
};

const W39F3010 = props => {
    const { location } = props;
    const [formID] = useState("W39F3010");
    const [iPermission, setIPermission] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const [dataCboStatusGoal, setDataCboStatusGoal] = useState([]);
    const [dataCboGoalTable, setDataCboGoalTable] = useState([]);
    const [dataCboEmployees, setDataCboEmployees] = useState({ rows: [], total: 0 });
    const [currentEmployee, setCurrentEmployee] = useState(() => {
        return Config.getUser({ EmployeeID: Config.profile.HREmployeeID || "" });
    });
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [tempDateFrom, setTempDateFrom] = useState(null);
    const [tempDateTo, setTempDateTo] = useState(null);
    const [isSetDate, setIsSetDate] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState("");
    const [isLoadingCboEmployee, setLoadingCboEmployee] = useState(false);
    const [toggleColorDes, setToggleColorDes] = useState(false);

    const [dataGrid, setDataGrid] = useState([]);
    const [totalData, setTotalData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [showPopupW39F3011, setShowPopupW39F3011] = useState(false);
    const [isOpenW39F3011FromW39F3005, setIsOpenW39F3011FromW39F3005] = useState(true);

    const dispatch = useDispatch();

    const Language = useRef(Config.language || "84");
    const filter = useRef({
        GoalPlanID: "",
        EmployeeID: "",
        StatusID: null,
        DateFrom: null,
        DateTo: null,
        skip: 0,
        limit: 20,
    });
    const dateFilter = useRef();
    const btnColorDes = useRef(null);
    const rowData = useRef({
        VoucherID: "",
        TransID: "",
        StatusID: 0,
        IsEdit: 0,
        Mode: 0,
    });

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission("W39F3010", isPer => {
                setIPermission(isPer);
            })
        );
    };

    const loadData = () => {
        const params = {
            DivisionID: Config.getDivisionID() || "",
            FormID: formID,
            UserID: Config.profile.UserID || "",
            Language: Language.current,
            GoalPlanID: filter.current.GoalPlanID,
            EmployeeID: filter.current.EmployeeID,
            StatusID: filter.current.StatusID,
            DateFrom: filter.current.DateFrom,
            DateTo: filter.current.DateTo,
            Skip: filter.current.skip,
            Limit: filter.current.limit,
        };
        setLoading(true);
        dispatch(
            W39F3010Actions.loadGrid(params, (errors, data) => {
                setLoading(false);
                if (errors) {
                    const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    setTotalData(data?.total || null);
                    setDataGrid(data?.rows || []);
                    if (isFirstLoad) {
                        setIsFirstLoad(false);
                        const localState = location?.state;
                        if (!_.isEmpty(data?.rows)) {
                            if (localState?.VoucherID || localState?.voucher_id) {
                                let dataSelected = _.find(data.rows, function (o) {
                                    return o.TransID === (localState?.VoucherID || localState?.voucher_id);
                                });
                                if (dataSelected) onShowW39F3011(dataSelected, "edit");
                            }
                        }
                    }
                }
            })
        );
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iPermission) return;
        loadData();
        onOpenW39F3011FromW39F3005();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    useEffect(() => {
        const localState = location?.state;
        if (!_.isEmpty(dataGrid)) {
            if (localState?.VoucherID || localState?.voucher_id) {
                let data = _.find(dataGrid, function (o) {
                    return o.TransID === (localState?.VoucherID || localState?.voucher_id);
                });
                if (data) onShowW39F3011(data, "edit");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const paramFilterCboEmployee = {
        skip: 0,
        limit: 10,
        search: "",
    };

    const loadCboStatusGoal = () => {
        const param = {
            Language: Config.language || "84",
            FormID: formID,
        };
        dispatch(
            W39F3010Actions.loadStatus(param, (err, data) => {
                if (err) return Config.popup.show("ERROR", err);
                if (data) {
                    setDataCboStatusGoal(data);
                }
            })
        );
    };

    const loadCboEmployees = isReset => {
        const param = {
            Type: "EmployeeID",
            FormID: "W39F3010",
            Language: Config.language || "84",
            ...paramFilterCboEmployee,
        };
        setLoadingCboEmployee(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployee(false);
                if (err) return Config.popup.show("ERROR", err);
                if (data) {
                    const rows = _.get(data, "rows", false) || data;
                    const total = _.get(data, "total", 0) || _.size(data);
                    setDataCboEmployees({ rows: isReset ? rows : _.concat(dataCboEmployees.rows, rows), total });
                }
            })
        );
    };

    const loadCboGoalTable = () => {
        dispatch(
            W39F3010Actions.getComboGoalTable((err, data) => {
                if (err) return Config.popup.show("ERROR", err);
                if (data) {
                    setDataCboGoalTable(data);
                }
            })
        );
    };

    const onLoadCboFilter = () => {
        loadCboStatusGoal();
        loadCboEmployees();
        loadCboGoalTable();
    };
    const filterChange = (key, value) => {
        switch (key) {
            case "GoalPlanID":
                filter.current[key] = value ? value : "";
                break;
            case "EmployeeID":
                filter.current[key] = value ? value.EmployeeID : "";
                break;
            default:
                filter.current[key] = value;
                break;
        }
    };
    const openDatePicker = e => {
        const target = e.currentTarget;
        if (dateFilter) {
            dateFilter.current.instance.show(target);
        }
    };

    const closeDatePicker = () => {
        if (dateFilter) {
            dateFilter.current.instance.hide();
        }
    };

    const setDatePicker = () => {
        setIsSetDate(true);
        setDateFrom(tempDateFrom);
        setDateTo(tempDateTo);
        filterChange("DateFrom", tempDateFrom);
        filterChange("DateTo", tempDateTo);
        setTimeout(() => {
            if (dateFilter) {
                dateFilter.current.instance.hide();
            }
        }, 10);
    };

    const dateRangesArr = [
        {
            label: Config.lang("Mot_tuan_truoc"),
            value: "1_week_before",
        },
        {
            label: Config.lang("Tuan_nay"),
            value: "current_week",
        },
        {
            label: Config.lang("Tuan_truoc"),
            value: "last_week",
        },
        {
            label: Config.lang("Mot_thang_truoc"),
            value: "1_month_before",
        },
        {
            label: Config.lang("Thang_nay"),
            value: "current_month",
        },
        {
            label: Config.lang("Thang_truoc"),
            value: "last_month",
        },
        {
            label: Config.lang("Quy_nay"),
            value: "current_quarter",
        },
        {
            label: Config.lang("Quy_truoc"),
            value: "last_quarter",
        },
        {
            label: Config.lang("Tat_ca"),
            value: "all",
        },
    ];

    const onDatePickerHidding = () => {
        setSelectedDateRange("");
        if (isSetDate) {
            setTempDateFrom(dateFrom => dateFrom);
            setTempDateTo(dateTo => dateTo);
            setIsSetDate(false);
        } else {
            setTempDateFrom(dateFrom);
            setTempDateTo(dateTo);
        }
    };

    const searchGoal = () => {
        if (filter.current.EmployeeID && filter.current.EmployeeID !== currentEmployee.EmployeeID) {
            const newEmployee = Config.getUser({ EmployeeID: filter.current.EmployeeID });
            setCurrentEmployee(newEmployee);
        }
        loadData();
    };

    const clearDatePicker = e => {
        if (dateFrom || dateTo) {
            e.stopPropagation();
            setDateFrom(null);
            setDateTo(null);
            setTempDateFrom(null);
            setTempDateTo(null);
            filterChange("DateFrom", null);
            filterChange("DateTo", null);
        }
    };

    const setDatePickerByRange = value => {
        setSelectedDateRange(value);
        switch (value) {
            case "1_week_before": {
                const newDate = moment().subtract(1, "week").format("YYYY-MM-DD");
                setTempDateFrom(newDate);
                setTempDateTo(newDate);
                break;
            }
            case "current_week": {
                const newDateFrom = moment().startOf("isoWeek").format("YYYY-MM-DD");
                const newDateTo = moment().endOf("isoWeek").format("YYYY-MM-DD");
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case "last_week": {
                const newDateFrom = moment().subtract(1, "week").startOf("isoWeek").format("YYYY-MM-DD");
                const newDateTo = moment().subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD");
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case "1_month_before": {
                const newDate = moment().subtract(1, "month").format("YYYY-MM-DD");
                setTempDateFrom(newDate);
                setTempDateTo(newDate);
                break;
            }
            case "current_month": {
                const newDateFrom = moment().startOf("month").format("YYYY-MM-DD");
                const newDateTo = moment().endOf("month").format("YYYY-MM-DD");
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case "last_month": {
                const newDateFrom = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
                const newDateTo = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case "current_quarter": {
                const newDateFrom = moment().startOf("quarter").format("YYYY-MM-DD");
                const newDateTo = moment().endOf("quarter").format("YYYY-MM-DD");
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case "last_quarter": {
                const newDateFrom = moment().subtract(1, "quarter").startOf("quarter").format("YYYY-MM-DD");
                const newDateTo = moment().subtract(1, "quarter").endOf("quarter").format("YYYY-MM-DD");
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case "all": {
                setTempDateFrom(null);
                setTempDateTo(null);
                break;
            }
            default:
                break;
        }
    };

    const handleChangeFilter = (key, value) => {
        switch (key) {
            case "TempDateFrom":
                setTempDateFrom(value);
                setSelectedDateRange("");
                break;
            case "TempDateTo":
                setTempDateTo(value);
                setSelectedDateRange("");
                break;
            default:
                break;
        }
    };

    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => (
                    <>
                        <div style={{ marginBottom: "7px" }}>
                            <Combo
                                label={Config.lang("Bang_muc_tieu")}
                                shrink={true}
                                dataSource={dataCboGoalTable}
                                displayExpr={"GoalPlanName"}
                                valueExpr={"GoalPlanID"}
                                onValueChanged={e => filterChange("GoalPlanID", e.value)}
                                showClearButton={true}
                                value={filter.current.GoalPlanID}
                                placeholder={Config.lang("Chon")}
                            />
                        </div>
                        <div style={{ marginBottom: "7px" }}>
                            <Combo.LoadMore
                                dataSource={dataCboEmployees}
                                skip={paramFilterCboEmployee.skip}
                                limit={paramFilterCboEmployee.limit}
                                displayExpr={"EmployeeName"}
                                keyExpr={"EmployeeID"}
                                valueExpr={"EmployeeID"}
                                value={filter.current.EmployeeID}
                                loading={isLoadingCboEmployee}
                                stylingMode={"outlined"}
                                label={Config.lang("Nhan_vien")}
                                shrink={true}
                                placeholder={Config.lang("Chon")}
                                itemRender={e => {
                                    const { data } = e;
                                    if (!data) return null;
                                    return data.EmployeeID + " - " + data.EmployeeName;
                                }}
                                showClearButton={true}
                                onValueChanged={e => filterChange("EmployeeID", e.value)}
                                onInputChanged={e => {
                                    paramFilterCboEmployee.search = e.target.value;
                                    paramFilterCboEmployee.skip = 0;
                                    loadCboEmployees(true);
                                }}
                                onLoadMore={e => {
                                    paramFilterCboEmployee.skip = e.skip;
                                    paramFilterCboEmployee.limit = e.limit;
                                    loadCboEmployees();
                                }}
                            />
                        </div>
                        <div style={{ marginBottom: "7px" }}>
                            <Combo
                                label={Config.lang("Trang_thai")}
                                shrink={true}
                                dataSource={dataCboStatusGoal}
                                displayExpr={"StatusName"}
                                valueExpr={"StatusID"}
                                onValueChanged={e => filterChange("StatusID", e.value)}
                                showClearButton={true}
                                value={filter.current.StatusID}
                                placeholder={Config.lang("Chon")}
                            />
                        </div>
                        <div style={{ marginBottom: "7px", display: "flow-root" }}>
                            <div style={styles.dateFilter} onClick={openDatePicker}>
                                <label style={styles.labelDateFilter}>{Config.lang("Thoi_gian")}</label>
                                <div style={{ ...styles.inputDateFilter, color: dateFrom || dateTo ? "#222B45" : "", fontWeight: 500 }}>
                                    {dateFrom || dateTo
                                        ? `${dateFrom ? moment(dateFrom).format("DD/MM/YYYY") : ""} - ${dateTo ? moment(dateTo).format("DD/MM/YYYY") : ""}`
                                        : "dd/mm/yyyy - dd/mm/yyyy"}
                                </div>
                                <Icon
                                    style={styles.iconDateFilter}
                                    name={dateFrom || dateTo ? "Cancel" : "Calendar"}
                                    width={20}
                                    height={20}
                                    viewBox
                                    onClick={clearDatePicker}
                                />
                            </div>
                            <Popover reference={dateFilter} maxWidth={550} position={"bottom"} shading={false} onHiding={onDatePickerHidding}>
                                <div style={styles.datePicker}>
                                    <Row style={{ padding: 15 }}>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <DateBoxPicker
                                                label={Config.lang("Tu")}
                                                shrink={true}
                                                dateBoxProps={{ max: tempDateTo ? tempDateTo : null }}
                                                onValueChanged={e => handleChangeFilter("TempDateFrom", e.value)}
                                                showClearButton={true}
                                                placeholder={"dd/mm/yyyy"}
                                                value={tempDateFrom || ""}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <DateBoxPicker
                                                label={Config.lang("Den")}
                                                shrink={true}
                                                dateBoxProps={{ min: tempDateFrom ? tempDateFrom : null }}
                                                onValueChanged={e => handleChangeFilter("TempDateTo", e.value)}
                                                showClearButton={true}
                                                placeholder={"dd/mm/yyyy"}
                                                value={tempDateTo || ""}
                                            />
                                        </Col>
                                    </Row>
                                    <div style={styles.listDateRanges}>
                                        {dateRangesArr.map((i, index) => {
                                            return (
                                                <ButtonGeneral
                                                    key={index}
                                                    name={i.label}
                                                    size={"large"}
                                                    variant={"text"}
                                                    onClick={() => setDatePickerByRange(i.value)}
                                                    style={{ marginRight: 5, backgroundColor: selectedDateRange === i.value ? "#EFF5FF" : "" }}
                                                />
                                            );
                                        })}
                                    </div>
                                    <div style={styles.divButtonDate}>
                                        <Button text={Config.lang("Dong1")} viewType="outlined" onClick={closeDatePicker} style={{ marginRight: 5 }} />
                                        <Button text={Config.lang("Chon")} viewType="filled" color="info" onClick={setDatePicker} />
                                    </div>
                                </div>
                            </Popover>
                        </div>
                        <div style={styles.divSearchButton}>
                            <Button
                                text={Config.lang("Tim_kiem")}
                                viewType="outlined"
                                onClick={searchGoal}
                                startIcon={<Icon name={"Search"} width={"100%"} height={"100%"} color={"primary"} viewBox />}
                            />
                        </div>
                    </>
                )}
            />
        );
    };

    const onChangePage = page => {
        filter.current.skip = page * filter.current.limit;
        loadData();
    };

    const onChangePerPage = perPage => {
        filter.current.skip = 0;
        filter.current.limit = perPage;
        loadData();
    };
    const saveHistory = async data => {
        if (!data) return false;
        const TransID = data.TransID;
        if (TransID) {
            // data Content
            const _data = {
                EmployeeID: data?.EmployeeID || "",
                EmployeeName: data?.EmployeeName || "",
                GoalPlanName: data?.GoalPlanName || "",
                PeriodName: data?.PeriodName || "",
                FinishDate: data?.FinishDate || "",
                StatusName: data?.StatusName || "",
                Result: data?.Result || "",
            };

            //name Content
            const captions = {
                EmployeeID: "ma_nhan_vien",
                EmployeeName: "ten_nhan_vien",
                GoalPlanName: "bang_muc_tieu",
                PeriodName: "khoang_thoi_gian",
                FinishDate: "ngay_hoan_thanh",
                StatusName: "trang_thai",
                Result: "ket_qua_review",
            };
            const options = {
                data: _data,
                captions: captions,
                action: 3,
                ModuleID: "D39",
                TransID: TransID,
                TransactionID: "W39F3010",
                TransactionName: "Danh sách review mục tiêu",
            };
            const history = new History(options); //Init history
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const onShowW39F3011 = (data, mode) => {
        if (!(iPermission > 0)) return;
        if (data) {
            rowData.current.VoucherID = data.VoucherID;
            rowData.current.TransID = data.TransID;
            rowData.current.StatusID = Number(data.StatusID);
            rowData.current.IsEdit = data.IsEdit;
            rowData.current.Mode = mode;
            setShowPopupW39F3011(true);
        }
    };

    const onDeleteRow = data => {
        const params = {
            DivisionID: Config.getDivisionID() || "",
            FormID: formID,
            UserID: Config.profile.UserID || "",
            Language: Config.language || "84",
            TransID: data.TransID,
            Mode: 1,
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            setLoading(true);
            dispatch(
                W39F3010Actions.deleteRow(params, async errors => {
                    setLoading(false);
                    if (errors) {
                        const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    } else {
                        await saveHistory(data);
                        loadData();
                    }
                })
            );
        });
    };
    const renderButtonAction = e => {
        return (
            <GridActionBar>
                <div className="display_row">
                    <ButtonIcon
                        circular
                        name={"View"}
                        onClick={() => onShowW39F3011(e.data, "view")}
                        viewType={"text"}
                    />
                    <Divider direction="vertical" />
                    <ButtonIcon
                        circular
                        name={"Edit"}
                        onClick={() => onShowW39F3011(e.data, "edit")}
                        viewType={"text"}
                        disabled={iPermission < 2 || e.data?.IsEdit === 0}
                    />
                    <Divider direction="vertical" />
                    <ButtonIcon
                        circular
                        name={"Delete"}
                        onClick={() => onDeleteRow(e.data)}
                        viewType={"text"}
                        disabled={iPermission < 3 || e.data?.IsEdit === 0 || e.data?.StatusID > 0}
                    />
                </div>
            </GridActionBar>
        );
    };

    const getStatusColor = StatusID => {
        switch (Number(StatusID)) {
            case 0:
                return "info";
            case 1:
                return "primary";
            case 2:
                return "success";
            case 4:
            case 5:
            case 100:
                return "danger";
            default:
                return "primary";
        }
    };

    const renderStatus = e => {
        return <Status viewType="filled" text={e?.data.StatusName || ""} color={getStatusColor(e?.data.StatusID || "")} />;
    };

    const onToggleColorDes = () => {
        setToggleColorDes(!toggleColorDes);
    };

    const colorStatusArr = [
        {
            id: 0,
            label: `0 ≤ ${Config.lang("Ket_qua")} ≤ 30`,
            colorCode: Config.color ? Config.color["Success"] : "#00C875",
            colorStr: "success",
        },
        {
            id: 1,
            label: `30 < ${Config.lang("Ket_qua")} ≤ 70`,
            colorCode: Config.color ? Config.color["Warning"] : "#FFAA00",
            colorStr: "warning",
        },
        {
            id: 2,
            label: `70 < ${Config.lang("Ket_qua")} ≤ 100`,
            colorCode: Config.color ? Config.color["Info"] : "#0095FF",
            colorStr: "info",
        },
    ];
    const getProgressColor = Result => {
        if (0 <= Result && Result < 30) return Config.color ? Config.color["Success"] : "#00C875";
        if (30 <= Result && Result < 70) return Config.color ? Config.color["Warning"] : "#FFAA00";
        if (70 <= Result && Result < 100) return Config.color ? Config.color["Info"] : "#0095FF";
    };

    const renderProgress = e => {
        return (
            <div style={{ height: "100%" }}>
                <LinearProgress color={getProgressColor(e?.data.Result || 0)} showValue determinate percent={e?.data.Result || ""} duration={1} />
            </div>
        );
    };

    const onOpenW39F3011FromW39F3005 = () => {
        const { state: { goalProps: { TransID, IsEdit } = {}} = {}} = location;
        const url = new URL(window.location.href);
        const transIDP = url.searchParams.get('TransID');
        const isEditP = Number.isInteger(Number(url.searchParams.get('IsEdit'))) ? Number(url.searchParams.get('IsEdit')) : 0;
        let mode = "view";
        let goalData = null;
        if (isOpenW39F3011FromW39F3005) {
            if (TransID) {
                goalData = dataGrid?.find(i => i.TransID === TransID) ?? { TransID: TransID, StatusID: 0, IsEdit: 0 };
                if (goalData && IsEdit) {
                    goalData.IsEdit = IsEdit;
                    mode = "edit";
                }
            } else if (transIDP) {
                goalData = dataGrid?.find(i => i.TransID === transIDP) ?? { TransID: transIDP, StatusID: 0, IsEdit: 0 };
                if (goalData && isEditP) {
                    goalData.IsEdit = isEditP;
                    mode = "edit";
                }
            }
            if (goalData) {
                onShowW39F3011(goalData, mode);
                setIsOpenW39F3011FromW39F3005(false);
            }
        }
    };

    return (
        <>
            <ActionToolbar title={Config.lang("Review_muc_tieu")} alignment={"flex-end"}>
                <Button
                    ref={btnColorDes}
                    color={"primary"}
                    viewType={"outlined"}
                    text={Config.lang("Mo_ta_mau_sac")}
                    startIcon={<InlineSVG src={require("../../../../assets/images/general/color-description.svg")} width={24} height={24} />}
                    onClick={onToggleColorDes}
                />
            </ActionToolbar>
            <div className={"hidden"}>{renderFilter()}</div>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <GridContainer
                        itemPerPage={filter.current.limit}
                        skipPerPage={filter.current.skip}
                        totalItems={totalData}
                        loading={loading}
                        style={{ border: "none" }}
                        dataSource={dataGrid}
                        showBorders={false}
                        typePaging={"remote"}
                        showColumnLines={false}
                        showRowLines={false}
                        hoverStateEnabled={true}
                        onChangePage={onChangePage}
                        onChangePerPage={onChangePerPage}
                        height={Config.getHeightGrid()}
                    >
                        <Column caption={Config.lang("Hanh_dong")} width={110} alignment={"center"} visible={Config.isMobile} cellRender={renderButtonAction} />
                        <Column caption={Config.lang("STT")} cellRender={e => filter.current.skip + e.rowIndex + 1} />
                        <Column dataField={"EmployeeID"} caption={Config.lang("Ma_nhan_vien")} />
                        <Column dataField={"EmployeeName"} caption={Config.lang("Ten_nhan_vien")} />
                        <Column dataField={"GoalPlanName"} caption={Config.lang("Bang_muc_tieu")} />
                        <Column dataField={"PeriodName"} caption={Config.lang("Giai_doan")} />
                        <Column dataField={"FinishDate"} dataType={"date"} format={"dd/MM/yyyy"} caption={Config.lang("Ngay_hoan_thanh")} />
                        <Column caption={Config.lang("Trang_thai")} width={138} cellRender={renderStatus} />
                        <Column caption={Config.lang("Ket_qua_review")} cellRender={renderProgress} />
                        <Column alignment={"center"} fixed={true} fixedPosition={"right"} visible={!Config.isMobile} cellRender={renderButtonAction} />
                    </GridContainer>
                </Col>
            </Row>
            <CorePopover
                open={toggleColorDes}
                onClose={onToggleColorDes}
                anchor={btnColorDes}
                width={"fit-content"}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 16,
                    gap: 10,
                }}
            >
                <Typography type={"p2"} style={{ fontWeight: "bold" }}>
                    {Config.lang("Trang_thai_muc_tieu")}
                </Typography>
                {colorStatusArr.map(i => {
                    return (
                        <div key={i.id} style={styles.divColorStatusCont}>
                            <div style={{ ...styles.divColorStatus, background: i.colorCode }}></div>
                            <Typography type={"p2"}>{i.label}</Typography>
                        </div>
                    );
                })}
            </CorePopover>
            <W39F3011
                open={showPopupW39F3011}
                mode={rowData.current.Mode}
                onClose={() => setShowPopupW39F3011(false)}
                VoucherID={rowData.current.VoucherID}
                TransID={rowData.current.TransID}
                IsEdit={rowData.current.IsEdit}
                StatusID={rowData.current.StatusID}
                loadDataW39F3010={loadData}
            />
        </>
    );
};

export default W39F3010;
