/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 16/09/2021
 * @Example
 */

import { Column } from "devextreme-react/data-grid";
import { Button, ButtonIcon, Chip, DatePicker, Label, Tooltip } from 'diginet-core-ui/components';
import { Last } from 'diginet-core-ui/icons';
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W39F3035Actions from "../../../../redux/W3X/W39F3035/W39F3035_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Filter from "../../../filter/filter";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import { browserHistory } from 'react-router';
const W39F3035 = (props) => {
    const { dataGrid } = props;
    let dataRow = [];
    const FormID = "W39F3035";
    const Language = Config.language || "84";
    if (!_.isEmpty(dataGrid)) dataRow = dataGrid.map((d, idx) => ({ ...d, STT: idx + 1 }));
    const [iPermission, setPermission] = useState(0);
    const [gridLoading, setGridLoading] = useState(false);
    const [dataFilter, setDataFilter] = useState({
        DateFrom: null,
        DateTo: null,
    });

    const currentRowClick = useRef({});
    useEffect(() => {
        const loadPermission = async () => {
            await props.generalActions.getPermission("W39F3035", (isPer) => {
                setPermission(isPer);
            });
        };
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (iPermission) {
            loadDataGrid();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    const onSearch = () => {
        loadDataGrid();
    };

    const loadDataGrid = () => {
        const { DateFrom, DateTo } = dataFilter;
        const param = {
            Language,
            FormID,
            DateFrom,
            DateTo,
        };
        setGridLoading(true);
        props.w39f3035Actions.getDataGrid(param, (error) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const onView = (data = {}) => {
        if (_.isEmpty(data)) return;
        currentRowClick.current = data;
        // Màn hình W39F3036
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F3036",
            state: { mode: 'view', ...data }
        });
    };

    const onEdit = (data = {}) => {
        if (_.isEmpty(data)) return;
        currentRowClick.current = data;
        // Màn hình W39F3036
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F3036",
            state: { mode: 'edit', ...data }
        });
    };

    const renderAction = (e) => {
        const data = _.get(e, "row.data", {});
        return (
            <GridActionBar >
                <div style={{ display: 'flex' }}>
                    <Tooltip title={Config.lang("Xem")} >
                        <ButtonIcon
                            circular
                            viewBox
                            name={"View"}
                            aria-label={"view"}
                            viewType={"text"}
                            disabled={!(iPermission > 0)}
                            onClick={() => onView(data)}
                            style={{ cursor: 'pointer' }} />
                    </Tooltip>
                    <Tooltip title={Config.lang("Sua")} >
                        <ButtonIcon
                            circular
                            viewBox
                            name={"Edit"}
                            aria-label={"edit"}
                            viewType={"text"}
                            disabled={!(iPermission > 2)}
                            onClick={() => onEdit(data)}
                            style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            </GridActionBar>
        );
    };

    const filterChange = (filterName = "", e) => {
        if (!e || _.isEmpty(filterName)) return;
        switch (filterName) {
            case "DateFrom":
            case "DateTo":
                setDataFilter({
                    ...dataFilter,
                    [filterName]: _.get(e, "value", "")
                });
                break;
            default:
                break;
        }
    };

    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={true}
                isUseDDCore={true}
                openOnFieldClick={false}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6} >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Label style={{ paddingBottom: 15, minWidth: 22 }} className={"mgr5"}>{Config.lang("Tu")}</Label>
                                        <DatePicker
                                            clearAble
                                            width={"100%"}
                                            margin={"normal"}
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            max={dataFilter.DateTo}
                                            value={dataFilter.DateFrom}
                                            onChange={e => filterChange("DateFrom", e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Label style={{ paddingBottom: 15, minWidth: 22 }} className={"mgr5"}>{Config.lang("Den")}</Label>
                                        <DatePicker
                                            clearAble
                                            width={"100%"}
                                            margin={"normal"}
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            min={dataFilter.DateFrom}
                                            value={dataFilter.DateTo}
                                            onChange={e => filterChange("DateTo", e)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ display: "flex", justifyContent: "center" }}  >
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const renderCustomTagColumn = (e) => {
        if (!e) return;
        if (e.value === 1) { // === 1 thì hiển thị
            return (
                <Chip
                    size={"small"}
                    color={"info"}
                    viewType={"filled"}
                    label={Config.lang("Da_chuyen_kq")}
                    startIcon={<Last color={"white"} />}
                />
            )
        } else {
            return;
        }
    };

    return (
        <>
            <FormGroup>
                <div className={"hidden"}>{renderFilter()}</div>
                <ActionToolbar title={Config.lang("Xep_loai_danh_gia_nhan_vien")}>
                </ActionToolbar>
            </FormGroup>
            <GridContainer
                style={{ border: "none" }}
                listPerPage={[20, 40, 60, 80]}
                typePaging={"normal"}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                hoverStateEnabled={true}
                loading={gridLoading}
                dataSource={dataRow}
                noDataText={Config.lang("No_data")}
                totalItems={(Number(dataRow)) || 0}
                height={Config.getHeightGrid()}
                onRowDblClick={(e) => {
                    onView(e);
                }}
                filterRow={{
                    visible: true,
                    showOperationChooser: false,
                }}
            >
                <Column
                    alignment={"center"}
                    allowFiltering={false}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile}
                    cellRender={renderAction}
                />
                <Column
                    width={70}
                    dataField={"STT"}
                    alignment={"center"}
                    allowFiltering={false}
                    caption={Config.lang("STT")}
                />
                <Column
                    width={260}
                    caption={Config.lang("Ten_bang_tong_hop")}
                    dataField={"GoalPlanName"}
                />
                <Column
                    width={400}
                    caption={Config.lang("Mo_ta")}
                    dataField={"Description"}
                />
                <Column
                    caption={""}
                    width={200}
                    allowFiltering={false}
                    dataField={"IsTransferred"}
                    cellRender={renderCustomTagColumn}
                />
                <Column
                    width={200}
                    alignment={"center"}
                    dataField={"DateFormTo"}
                    caption={Config.lang("Khoang_thoi_gian")}
                />
                <Column
                    fixed={true}
                    allowFiltering={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile}
                    cellRender={renderAction}
                />
            </GridContainer>
        </>
    );
};

export default compose(
    connect(state => ({
        dataGrid: state.W39F3035.dataGrid
    }), dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w39f3035Actions: bindActionCreators(W39F3035Actions, dispatch)
    })))(W39F3035);
