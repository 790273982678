/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 16/4/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    gap5: {
        gap: 5
    },
    gap10: {
        gap: 10
    },
    linkDisabled: {
        cursor: 'default',
        pointerEvents: 'none',
        color: '#7F828E !important',
    },
    spanLink: {
        width: 'fit-content',
        color: '#0095FF',
        textDecoration: 'underline',
    },
    pointerCursor: {
        cursor: 'pointer',
    },
}));