/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 7/5/2021
 * @Example
 */

import { Avatar, CircularProgress, Collapse, Typography } from 'diginet-core-ui/components';
import { ArrowUp } from 'diginet-core-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import Config from '../../../../../config';
import Api from '../../../../../services/api';

const W39F3030Goal = (props) => {
    const { data, classes, colorStatusArr } = props;

    const [expandTargetResult, setExpandTargetResult] = useState(false);
    const [dataTargetResult, setDataTargetResult] = useState([]);

    const toogleExpandTargetResult = async () => {
        setExpandTargetResult(!expandTargetResult);
        if (!expandTargetResult && dataTargetResult.length === 0) {
            const params = {
                DivisionID: Config.getDivisionID() ?? '',
                FormID: 'W39F3000',
                Language: Config?.language ?? '84',
                UserID: Config?.profile?.UserID ?? '',
                GoalID: data?.GoalID ?? '',
            };

            const res = await Api.put('/W39F3000/load-target-result', params);
            const resData = res?.data ?? null;
            const resDataStatus = resData?.Status ?? 0;
            if (res && (!resData || resDataStatus === 1)) {
                const errMsg = resData?.Message ?? res?.message ?? Config.lang('Co_loi_xay_ra_trong_qua_trinh_xu_ly');
                return Config.popup2.show('ERROR', errMsg);
            }
            setDataTargetResult(resData);
        }
    };

    const getColorCode = (status, validDateTo, finishDate) => {
        let color = colorStatusArr[0].colorCode;
        const today = moment();
        if (validDateTo) {
            if (status === 0) {
                if (today.isAfter(validDateTo)) {
                    color = colorStatusArr[1].colorCode;
                } else {
                    color = colorStatusArr[0].colorCode;
                }
            } else if (finishDate && status === 1) {
                if (finishDate.isAfter(validDateTo)) {
                    color = colorStatusArr[3].colorCode;
                } else {
                    color = colorStatusArr[2].colorCode;
                }
            }
        }
        return color;
    };

    const validDateTo = data.ValidDateTo ? moment(data.ValidDateTo) : null;
    const finishDate = data.FinishDate ? moment(data.FinishDate) : null;
    const color = getColorCode(data.Status, validDateTo, finishDate);
    const user = Config.getUser({ EmployeeID: data.EmployeeID });

    return (
        <>
            <div className={`${data?.Type === 'Key' ? classes.gridTR : classes.gridGoal} ${classes.divGoal} ${classes.gap10}`}>
                <div className={`${classes.flexRow} ${classes.alignCenter} ${classes.gap10}`}>
                    {data?.Type === 'Object' && (
                        <span
                            onClick={toogleExpandTargetResult}
                            className={`${classes.iconExpandTransition} ${classes.pointerCursor}`}
                            style={{ color: '#7F828E', transform: expandTargetResult ? '' : 'rotate(90deg)' }}
                        >
                            <ArrowUp width={24} height={24} viewBox color={'currentColor'} />
                        </span>
                    )}
                    <div className={`${classes.flexColumn} ${classes.gap5} ${classes.hiddenOverflow}`}>
                        <Typography type={'h3'} className={`${classes.textOverflow}`}>
                            {data.GoalName}
                        </Typography>
                        <div className={`${classes.flexRow} ${classes.alignCenter} ${classes.gap20}`}>
                            {data?.Type === 'Object' && (
                                <div className={`${classes.flexRow} ${classes.alignCenter} ${classes.gap5}`}>
                                    <InlineSVG src={require('../icons/number_of_key.svg')} width={24} height={24} />
                                    <Typography type={'p2'} color={'secondary'} style={{ fontSize: 16 }}>
                                        {data?.NumberOfKey ?? ''}
                                    </Typography>
                                </div>
                            )}
                            <div className={`${classes.flexRow} ${classes.alignCenter} ${classes.gap5}`}>
                                <InlineSVG src={require('../icons/comment.svg')} width={24} height={24} />
                                <Typography type={'p2'} color={'secondary'} style={{ fontSize: 16 }}>
                                    {data?.NumComment ?? 0}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.flexColumn} ${classes.alignCenter} ${classes.gap5}`}>
                    <CircularProgress
                        color={color}
                        determinate
                        direction="bottom"
                        percent={data?.Result ?? 0}
                        percentColor="#7F828E"
                        size="sm"
                        strokeWidth={8}
                    />
                    {data?.ComputeMethod === 'Target' && (
                        <Typography type={'p2'} color={'secondary'} fullWidth={false}>
                            {data?.Value ?? 0}/{data?.Target ?? 0}
                        </Typography>
                    )}
                </div>
                <Typography type={'p2'}>{data?.Proportion ?? 0}%</Typography>
                <Typography type={'p2'}>{validDateTo?.format('DD/MM/YYYY') ?? ''}</Typography>
                <div className={`${classes.flexColumn} ${classes.gap5}`}>
                    <Avatar
                        clearAble={false}
                        width={24}
                        height={24}
                        readOnly
                        src={user?.UserPictureURL ?? ''}
                        outlined={'1px solid white'}
                        hoverAble
                        data={{
                            [Config.lang('Chuc_vu')]: user?.DutyName ?? '',
                            [Config.lang('Phong_ban')]: user?.DepartmentName ?? '',
                        }}
                    />
                    <Typography type={'p2'}>{user?.EmployeeName ?? ''}</Typography>
                </div>
            </div>
            <Collapse open={expandTargetResult} className={`${classes.listTR}`}>
                {data.Type === 'Object' &&
                    dataTargetResult.length > 0 &&
                    expandTargetResult &&
                    dataTargetResult.map((i, idx) => <W39F3030Goal key={i?.GoalID ?? idx} classes={classes} data={i} />)}
            </Collapse>
        </>
    );
};

W39F3030Goal.propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    colorStatusArr: PropTypes.array,
};

W39F3030Goal.defaultProps = {
    data: {},
    classes: {},
    colorStatusArr: [],
};

export default W39F3030Goal;
