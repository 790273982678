/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 14/4/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    alignCenter: {
        alignItems: 'center'
    },
    gap10: {
        gap: 10
    },
    ml30: {
        marginLeft: 30
    },
    divInput: {
        display: 'grid',
        gridTemplateColumns: '1fr minmax(100px, 3fr)'
    },
}))